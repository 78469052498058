noscript {
  background-color: red;
  color: white;
  height: 40px;
}

.App {
  text-align: center;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: "hidden";
  overflow-y: auto;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #415868;
}

div#root {
  position: relative;
  min-height: 100vh;
}

main.container.main .container-fluid {
  position: relative;

  padding: 0 !important;
}

main.container.main {
  padding-bottom: 10rem;
  /* Footer height */
}

ul.list-unstyled li,
ul li {
  padding-bottom: 3px;
}

.block-handle {
  padding: "0 20%";
  text-align: center;
}

.pointer {
  cursor: pointer !important;
}

.darkorange{
  color: #ff8c00 !important;
}

.proposal-deadline-text {
  color: red;
  font-size: 16px;
  font-weight: bold;
}

.btn-danger,
.MuiDataGrid-row .btn-danger {
  color: #fff;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.ck-editor__editable[role="textbox"] {
  /* editing area */
  min-height: 300px;
}
.ck-content .image {
  /* block images */
  max-width: 80%;
  margin: 20px auto;
}

#progreportsTable .form-control,
input[name="verify-input-unitTotals"],
input#units {
  padding: 0rem 0rem 0rem 0.2rem;
}

#progreportsTable .form-control {
  padding: 0.2rem;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  /* Footer height */
}

h1 {
  font-size: 36px;
  font-weight: 800;
  color: #415868;
}

h2 {
  font-size: 28px;
  font-weight: 800;
  color: #415868;
}

h3 {
  font-size: 20px;
  font-weight: 800;
  color: #415868;
  opacity: 0.9;
}

h3 {
  font-size: 16px;
  font-weight: 800;
  color: #415868;
}

h4 {
  font-size: 14px;
  font-weight: 800;
  color: #415868;
  padding: 0px;
  margin: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  cursor: pointer;
}

a:hover {
  color: #799447 !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
  display: inline-flex;
}

.MuiTablePagination-toolbar svg.MuiSvgIcon-root.MuiSelect-icon,
.MuiTablePagination-toolbar .MuiTablePagination-actions {
  margin-top: -0.6rem;
}

.MuiTablePagination-toolbar .MuiTablePagination-displayedRows {
  margin-top: 0.3rem;
}

.MuiTablePagination-toolbar .MuiSelect-select {
  margin-top: -0.8rem;
}

.UserMgmtTable .MuiInputBase-root {
  margin-top: -1rem;
}

thead.MuiTableHead-root {
  background-color: #000;
}

thead.MuiTableHead-root thead,
.MuiTableCell-head {
  color: #fff !important;
}

.MuiButtonBase-root.MuiTableSortLabel-root {
  color: #fff !important;
}

.MuiTableCell-head,
.MuiTableCell-head:hover,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-active,
.MuiTableSortLabel-icon,
.MuiTableSortLabel-icon:active,
.MuiTableSortLabel-icon:visited {
  color: #fff !important;
}

#ReportingPeriodsTbl .MuiTableCell-head {
  background-color: #000;
  color: #fff !important;
}

thead.MuiTableHead-root:hover {
  background-color: #000;
  color: #799447;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar-custom {
  background-color: #779442;
  height: 40px;
}

.navbar-green {
  background-color: #779442;
}

.tabbedNavbar-custom {
  background-color: #779442;
}

.tabbeddiv-custom-blue {
  background-color: #415868;
}

.tabbeddiv-custom:hover {
  cursor: pointer;
  background-color: rgb(197, 197, 197) !important;
}

.tabbeddiv-custom-blue:hover {
  cursor: pointer;
  background-color: rgb(197, 197, 197) !important;
}

/* Modify brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: white;
}

.nav-link {
  color: white !important;
}

a.nav-link:hover {
  cursor: pointer;
  color: black !important;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: #415868 !important;
}

.btn-primary:hover {
  background-color: #3c71c0c2 !important;
  -ms-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.pssfLoggedintext {
  padding: 24px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

img.ourLogo {
  width: 35%;
  height: 35%;
}

.pro-sidebar {
  position: fixed;
  top: 0;
  left: 0;
}

.container {
  margin-left: 16.5rem !important;
  /* Same as the width of the sidenav */
  padding: 0px 10px;
}

.container.main {
  margin-left: 0px !important;
  /* Same as the width of the sidenav */
  padding: 0px 10px;
  min-width: 100% !important;
}

/* The message box is shown when the user clicks on the password field */
#message {
  display: none;
  background: #f1f1f1;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
}

#message p {
  padding: 10px 35px;
  font-size: 18px;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  left: -35px;
  content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left: -35px;
  content: "✖";
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: rgb(245, 243, 243);
  padding: 1rem;
  border: 1px solid #ccc;
}

z .toolbar-class {
  border: 1px solid #ccc;
}

.sidebar-btn-wrapper svg {
  margin-right: 0.5rem;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper
  .pro-icon
  svg {
  color: #779442;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper
  .pro-icon
  svg:hover {
  color: #fff;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-item-content:not(:first-child),
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper
  .pro-arrow,
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  > .pro-inner-item
  > .pro-arrow-wrapper {
  color: #fff;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item
  > .pro-inner-item
  > .pro-item-content:hover:not(:first-child) {
  color: #e78726;
}

ul.pagination li.page-item {
  width: 2rem;
}

table {
  padding-bottom: 5rem;
}

main.container {
  padding-bottom: 100px;
}

/* 
minheight: "201px",
          height:"auto",
          maxHeight: "412px",

*/

@media (max-width: 609px) {
  .container {
    margin-left: 0 !important;
    padding: 0px 10px;
  }
}

@media (max-width: 767px) {
  .container {
    margin-left: 0 !important;
    padding: 0px 10px;
  }

  main.container.admin {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .our-card {
    height: 200px;
  }

  main.container,
  main.container.provider {
    width: 147%;
    max-width: 100%;
  }

  main.container table {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  nav#navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: left !important;
    justify-content: space-between !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  .our-card {
    height: 300px;
  }
}
